<template>
    <div class="home">
      <div class="container">
        <Top :activeTitle="activeTitle" :list="list"  />
          <div class="problem">
            <div class="problem_item" >            
              <div class="title">ปัญหาที่พบบ่อย</div>
              <div class="problem_detail" >
                <van-collapse v-model="activeName1" accordion border  >
                  <van-collapse-item :icon="require('@/assets/image/help.png')" v-for="(item, index) in commonProblemList[1]" :key="index" :title="item.title">{{ item.detail }}</van-collapse-item>
                </van-collapse>
              </div>
            </div>
          </div>
          <div class="problem">
            <div class="title">เติมเงิน</div>
            <div class="problem_detail">
              <van-collapse v-model="activeName2" accordion border>
                <van-collapse-item :icon="require('@/assets/image/help.png')"  v-for="(item, index) in commonProblemList[2]" :key="index" :title="item.title">{{ item.detail }}</van-collapse-item>
              </van-collapse>
            </div>
          </div>
          <div class="problem">
            <div class="title">บัญชี</div>
            <div class="problem_detail">
              <van-collapse v-model="activeName3" accordion border>
                <van-collapse-item :icon="require('@/assets/image/help.png')"  v-for="(item, index) in commonProblemList[3]" :key="index" :title="item.title">{{ item.detail }}</van-collapse-item>
              </van-collapse>
            </div>
          </div>
      </div>
      <FooterNav  :activeIndex="active"/>
      <FooderText />
    </div>
      
  </template>
  
  <script>
    import FooderText from "../components/FooterText.vue";
    import FooterNav from "../components/FooterNav.vue";
    import Top from "../components/Top.vue";
  
  export default {
    components: {
      FooderText,
      FooterNav,
      Top
    },
    data() {  
      return {
        active: 3,
        commonProblemList:[],
        activeName1: '1',
        activeName2: '1',
        activeName3: '1',
        activeTitle: '0',
        list:[
          {name:'ปัญหาที่พบบ่อย',url:'/CommonProblem'},
          {name:'ติดต่อเจ้าหน้าที่',url:'/Connect'},
          {name:'นโยบายความเป็นส่วนตัว',url:'/Privacy'},
          {name:'ข้อตกลงการใช้บริการ',url:'/CyberSecurity'},
        ]
      };
    },
    computed: {

    },
    mounted() {
  
    },
    created() {
      this.getCommonProblem()
      
    },
    methods:{

      getCommonProblem(){
          this.service.post('Home/getCommonProblem',{})
          .then((res) => {
              this.commonProblemList = res.data.data
          }) 
      },
      
    }
  };
  </script>
  
  <style lang="less">
    
    .van-collapse-item{
      margin-bottom: 16px;
    }
    .problem{
      margin-bottom: 60px;
      .title{
        color: #FFFFFF;
        font-size: 46px;
        margin-bottom: 12px;
        margin-top: 30px;
        font-family: 'kt';
      }
    
    }
    .problem_detail {
       .van-hairline--top-bottom::after, .van-hairline-unset--top-bottom::after, .van-collapse-item--border::after{
        border-top-width:0px;
        border-width:0px;
      }
      .van-cell::after{
        border-bottom: 0px;
      }
      
      .van-collapse-item .van-cell{
        background:linear-gradient(to right,#357BFF,#37DCF3)!important;
        border-radius: 15px;
        font-weight: bold;
        padding: 10px 20px;
        .van-cell__title{
          color:#FFFFFF;
          font-weight: bold;
          font-size: 26px;
        }
        .van-cell__left-icon{
          margin-top: 8px;
          line-height: 50px;
          height:35px
        }
      }
      .van-collapse-item .van-collapse-item__title--expanded{
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
      .van-collapse-item__content{
        color: #999999;
        font-size: 26px;
        white-space: break-spaces;
      }
      .van-cell__right-icon{
        color: #FFFFFF;
      }
    }
    
    .van-collapse-item__wrapper{
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }
    .van-icon img{
      width: 100%;
      height: 100%;
    }
    
  </style>
  
  